.inviteform-container {
    background-color: white;
    color: black;
    width: calc(100% - 10px);
    max-width: 650px;
    min-height: 200px;
    z-index: 205;
    border: 1px solid grey;
    box-shadow: 1px 1px 5px grey;
    margin-left: 5px;
}

.selected-option {
    background-color: rgb(88, 219, 116);
    border-radius: 5px;
    margin-left: 5px;
    padding: 4px;
}

.remove-selection-button:hover {
    cursor: pointer;
}