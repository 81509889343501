.selector-menu {
    border: 1px solid grey;
    box-shadow: 1px 1px 1px grey;
    z-index: 100;
    position: relative;
    background-color: white;
    color: black;
}

.selector-close-menu-button:hover {
    cursor: pointer;
}

.dropdown-toggle {
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    line-height: 1.5;
}

.selector-input {    
    background: url("https://petestewart72.pythonanywhere.com/media/images/avatars/profile_pic-633579e3-0529-4ec1-91ea-ab15b6c2bc75.png")  left     no-repeat;
    height:30px;
    padding-left:25px;
    background-color: white;
    color: black;
    height: 38px;
    }

    .dropdown-item:hover {
        cursor: default;
    }