.profile-container {
    margin-top: 48px;
}

.edit-profile-btn {
    color: grey;
}

.edit-profile-btn:hover {
    cursor: pointer;
    color: rgb(2, 212, 2);
}

.profile-page-pic {
    object-fit: cover;
    height: 20vw;
    width: 20vw;
    border-radius: 50%;
}

.muted-text {
    color: #ababab;
}