.avatar_sm {
    object-fit: cover;
    height: 20px;
    width: 20px;
    border-radius: 50%;
}

.channel-members-list {
    list-style-type: none;
}

.channel-page-pic {
    object-fit: cover;
    height: 20vw;
    width: 20vw;
    border-radius: 50%;
}

.channel-title {
    background-color: #2E2E2E;
    color: white;
}


.edit-channel-btn {
    color: grey;
}

.edit-channel-btn:hover {
    cursor: pointer;
    color: rgb(2, 212, 2);
}