.notifications-window {
    position: fixed;
    z-index: 215;
    background-color: white;
    box-shadow: 1px 1px 2px rgb(172, 172, 172, 0.5);
    margin-left: calc((66vw - 60vw));
    margin-top: -5px;
    max-height: 400px;
    width: 60vw;
    min-width: 200px;
    padding: 0px;
    color: black;
}

.notification {
    font-size: 0.9rem;
    border: 1px solid rgb(209, 209, 209);
    padding: 5px;
    margin: 0;
}

.notification:hover {
    cursor: default;
    background-color:  rgb(240, 240, 240);
}

.delete-notification-button:hover {
    cursor: pointer;
}