@import url(https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap);
body {
  margin: 0;
    font-family: 'Lato', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.selector-menu {
    border: 1px solid grey;
    box-shadow: 1px 1px 1px grey;
    z-index: 100;
    position: relative;
    background-color: white;
    color: black;
}

.selector-close-menu-button:hover {
    cursor: pointer;
}

.dropdown-toggle {
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    line-height: 1.5;
}

.selector-input {    
    background: url("https://petestewart72.pythonanywhere.com/media/images/avatars/profile_pic-633579e3-0529-4ec1-91ea-ab15b6c2bc75.png")  left     no-repeat;
    height:30px;
    padding-left:25px;
    background-color: white;
    color: black;
    height: 38px;
    }

    .dropdown-item:hover {
        cursor: default;
    }
.avatar_sm {
    object-fit: cover;
    height: 20px;
    width: 20px;
    border-radius: 50%;
}

.channel-members-list {
    list-style-type: none;
}

.channel-page-pic {
    object-fit: cover;
    height: 20vw;
    width: 20vw;
    border-radius: 50%;
}

.channel-title {
    background-color: #2E2E2E;
    color: white;
}


.edit-channel-btn {
    color: grey;
}

.edit-channel-btn:hover {
    cursor: pointer;
    color: rgb(2, 212, 2);
}
.chatroom-container {
    width: 100%;
    font-size: .8rem;
}

.chat-feed {
    height: calc(100vh - 238px); 
    overflow-y: scroll;
    width: 100%;
    padding-top: 40px;
    font-size: 1.1rem;
}

.chat-footer {
    background-color: #2E2E2E;
    color: white;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%; /* 100% of page width */
    padding: 0px; /* standard edge margin (15px) */
    height: 105px; /* space for input height (38px) + bottom padding (15px) */
}

.chat-message-form {
    width: 100%;
    text-align: center;
}

.chat-text-input-window {
    width: 95vw !important;
}



.message-button {
    color: grey;
    margin: 0 10px;
}

.message-button:hover {
    color: rgb(2, 212, 2);
    cursor: pointer;
}

.message-time {
    font-size: .6rem;
    color: grey;
}

.status-ticker {
    height: 20px;
    margin: 0;
}

.backdrop {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
    background-color: rgba(85, 85, 85, 0.5);
    opacity: .25;
}

.backdrop-clear {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
}
.calibrator {
    position: fixed;
    right: 0;
    top: 40;
    z-index: 205;
    background-color: white;
    color: black;
    border: 1px solid black;
    min-height: 300px;
    width: 350px;
    padding: 5px;
    margin-right: calc(50vw - 175px);
    margin-top: 50px;
}


.reactions {
    margin-top: -20px;
    font-size: 50%;
    z-index: 999;
}

.reactions .reactions-counter {
    display: flex;
    flex-wrap: wrap;
}

.reactions .emoji-mart {
    z-index: 999;
}

.reactions .emoji-mart-anchors {
    display: none;
}

.reactions .emoji-mart-search {
    display: none;
}

.reactions .emoji-mart-bar {
    display: none;
}

.reactions .emoji-mart-scroll {
    overflow: auto;
    height: auto;
}

.reactions .emoji-mart-category-label {
    display: none;
}

.message {
    margin: 5px 0 0 !important;
    overflow-wrap: break-word;
}

.message-sender {
    font-weight: bold;
}

.message-avatar {
    object-fit: cover;
    height: 30px;
    width: 30px;
    border-radius: 50%;
}

.react-to-message-button {
    color: grey;
}

.react-to-message-button:hover {
    color: yellow;
    cursor: pointer;
}

.delete-message-button:hover {
    color: red;
    cursor: pointer;
}

.edit-message-button:hover {
    color: blue;
    cursor: pointer;
}

.message-open-controls-button:hover {
    color: black !important;
    cursor: pointer;
}

.edit-message-textbox {
    outline: none;
    border: none;
    height: unset;
}
.inviteform-container {
    background-color: white;
    color: black;
    width: calc(100% - 10px);
    max-width: 650px;
    min-height: 200px;
    z-index: 205;
    border: 1px solid grey;
    box-shadow: 1px 1px 5px grey;
    margin-left: 5px;
}

.selected-option {
    background-color: rgb(88, 219, 116);
    border-radius: 5px;
    margin-left: 5px;
    padding: 4px;
}

.remove-selection-button:hover {
    cursor: pointer;
}

.party-container {
    min-height: calc(100vh - 48px);
    position: fixed;
    top: 48px;
    width: 100%;
}

.party-header {
    background-color: #1B1D21;
    height: 100px;
    position: fixed;
    top: 48px;
    width: 100%;
    left: 0;
    z-index: 99;
}

.party-room-body {
    height: calc(100vh - 188px);
    width: 100%;
    position: fixed;
    top: 105px;
}


.party-control-button {
    color: grey;
}

.party-control-button:hover {
    cursor: pointer;
    color: rgb(2, 212, 2);
}

.party-avatar {
    object-fit: cover;
    height: 15px;
    width: 15px;
    border-radius: 50%;
}


.feed-delay-message {
    width: 100%;
    padding: 0;
    margin: 0;
}

.party-tiny-header {
    font-size: .7rem;
    color: grey;
}

.event-status {
    font-size: 0.7rem;
}

.party-title {
    background-color: #2E2E2E;
    color: white;
    padding: 2px;
}

.party-card {
    background-color: rgb(236, 236, 236);
    color: black;
}

.party-card:hover {
    background-color: white;
    cursor: pointer;
}

.profile-container {
    margin-top: 48px;
}

.edit-profile-btn {
    color: grey;
}

.edit-profile-btn:hover {
    cursor: pointer;
    color: rgb(2, 212, 2);
}

.profile-page-pic {
    object-fit: cover;
    height: 20vw;
    width: 20vw;
    border-radius: 50%;
}

.muted-text {
    color: #ababab;
}
.image-preview-container {
    height: 100px;
    width: 100px;
    margin-bottom: 5px;
}


.image-preview {
    max-height: 100%;
    object-fit: cover;
    height: 100px;
    width: 100px;
    border-radius: 50%;
}

.image-field {
    opacity: 0;
    color: black;
}

.nav-bar {
    height: 48px;
    background-color: #2E2E2E;
    color: white;
    border-bottom: 1px solid #6C757D;
}

.nav-item {
    color: grey;
}

.nav-item:hover {
    cursor: pointer;
    color: rgb(2, 212, 2);
}

.nav-item-active {
    color: rgb(2, 212, 2);
}

.notification-bell {
    width: 40px;
}

.notification-dot {
    position: relative;
    top: -5px;
    left: -8px;
    font-size: 9px;
    vertical-align: super;
    }
.notifications-window {
    position: fixed;
    z-index: 215;
    background-color: white;
    box-shadow: 1px 1px 2px rgb(172, 172, 172, 0.5);
    margin-left: calc((66vw - 60vw));
    margin-top: -5px;
    max-height: 400px;
    width: 60vw;
    min-width: 200px;
    padding: 0px;
    color: black;
}

.notification {
    font-size: 0.9rem;
    border: 1px solid rgb(209, 209, 209);
    padding: 5px;
    margin: 0;
}

.notification:hover {
    cursor: default;
    background-color:  rgb(240, 240, 240);
}

.delete-notification-button:hover {
    cursor: pointer;
}
.sidedrawer {
    position: fixed;
    width: 280px;
    max-width: 70%;
    height: 100%;
    left: 0;
    top: 40;
    z-index: 200;
    background-color: white;
    color: black;
    padding: 32px 0px;
    box-sizing: border-box;
    transition: transform 0.3s ease-out;
    }

.sidedrawer-open {
    transform: translateX(0);
    box-shadow: 5px -1px 10px rgb(90, 90, 90);

}

.sidedrawer-closed {
    transform: translateX(-100%);
    
}

.sidedrawer ul {
    list-style: none;
    -webkit-padding-start: 15px;
            padding-inline-start: 15px;
}

.menu-icon {
    float: left;
    width: 30px;
}

.sidemenu-item:hover {
    cursor: pointer;
    color: #007BFF;
}
html {
    overflow:   scroll;
    background-color: #1B1D21;
    font-size: 1.1rem;
}
::-webkit-scrollbar {
    width: 0px;
    background: transparent; /* make scrollbar transparent */
}

.Layout {
    min-height: 100vh;
    margin: 0;
    background-color: #1B1D21;
    color: white;
}

.navbar-container {
    position: fixed;
    top: 0;
    width: 100%;
    height: 48px;
    z-index: 50;
}

.app-container {
    width: 100%;
    margin-top: 48px;
    min-height: calc(100vh - 48px);
}

.bright-hover:hover {
    cursor: pointer;
    color: rgb(2, 212, 2);
}
.login-container {
    background-color: #1B1D21;
    color: white;
}


.register-container {
    background-color: #1B1D21;
    color: white;
}
