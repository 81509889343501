
.nav-bar {
    height: 48px;
    background-color: #2E2E2E;
    color: white;
    border-bottom: 1px solid #6C757D;
}

.nav-item {
    color: grey;
}

.nav-item:hover {
    cursor: pointer;
    color: rgb(2, 212, 2);
}

.nav-item-active {
    color: rgb(2, 212, 2);
}

.notification-bell {
    width: 40px;
}

.notification-dot {
    position: relative;
    top: -5px;
    left: -8px;
    font-size: 9px;
    vertical-align: super;
    }