.message {
    margin: 5px 0 0 !important;
    overflow-wrap: break-word;
}

.message-sender {
    font-weight: bold;
}

.message-avatar {
    object-fit: cover;
    height: 30px;
    width: 30px;
    border-radius: 50%;
}

.react-to-message-button {
    color: grey;
}

.react-to-message-button:hover {
    color: yellow;
    cursor: pointer;
}

.delete-message-button:hover {
    color: red;
    cursor: pointer;
}

.edit-message-button:hover {
    color: blue;
    cursor: pointer;
}

.message-open-controls-button:hover {
    color: black !important;
    cursor: pointer;
}

.edit-message-textbox {
    outline: none;
    border: none;
    height: unset;
}