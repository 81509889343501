.party-container {
    min-height: calc(100vh - 48px);
    position: fixed;
    top: 48px;
    width: 100%;
}

.party-header {
    background-color: #1B1D21;
    height: 100px;
    position: fixed;
    top: 48px;
    width: 100%;
    left: 0;
    z-index: 99;
}

.party-room-body {
    height: calc(100vh - 188px);
    width: 100%;
    position: fixed;
    top: 105px;
}


.party-control-button {
    color: grey;
}

.party-control-button:hover {
    cursor: pointer;
    color: rgb(2, 212, 2);
}

.party-avatar {
    object-fit: cover;
    height: 15px;
    width: 15px;
    border-radius: 50%;
}


.feed-delay-message {
    width: 100%;
    padding: 0;
    margin: 0;
}

.party-tiny-header {
    font-size: .7rem;
    color: grey;
}

.event-status {
    font-size: 0.7rem;
}

.party-title {
    background-color: #2E2E2E;
    color: white;
    padding: 2px;
}
