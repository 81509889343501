.sidedrawer {
    position: fixed;
    width: 280px;
    max-width: 70%;
    height: 100%;
    left: 0;
    top: 40;
    z-index: 200;
    background-color: white;
    color: black;
    padding: 32px 0px;
    box-sizing: border-box;
    transition: transform 0.3s ease-out;
    }

.sidedrawer-open {
    transform: translateX(0);
    box-shadow: 5px -1px 10px rgb(90, 90, 90);

}

.sidedrawer-closed {
    transform: translateX(-100%);
    
}

.sidedrawer ul {
    list-style: none;
    padding-inline-start: 15px;
}

.menu-icon {
    float: left;
    width: 30px;
}

.sidemenu-item:hover {
    cursor: pointer;
    color: #007BFF;
}