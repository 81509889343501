html {
    overflow:   scroll;
    background-color: #1B1D21;
    font-size: 1.1rem;
}
::-webkit-scrollbar {
    width: 0px;
    background: transparent; /* make scrollbar transparent */
}

.Layout {
    min-height: 100vh;
    margin: 0;
    background-color: #1B1D21;
    color: white;
}

.navbar-container {
    position: fixed;
    top: 0;
    width: 100%;
    height: 48px;
    z-index: 50;
}

.app-container {
    width: 100%;
    margin-top: 48px;
    min-height: calc(100vh - 48px);
}

.bright-hover:hover {
    cursor: pointer;
    color: rgb(2, 212, 2);
}