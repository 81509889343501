.chatroom-container {
    width: 100%;
    font-size: .8rem;
}

.chat-feed {
    height: calc(100vh - 238px); 
    overflow-y: scroll;
    width: 100%;
    padding-top: 40px;
    font-size: 1.1rem;
}

.chat-footer {
    background-color: #2E2E2E;
    color: white;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%; /* 100% of page width */
    padding: 0px; /* standard edge margin (15px) */
    height: 105px; /* space for input height (38px) + bottom padding (15px) */
}

.chat-message-form {
    width: 100%;
    text-align: center;
}

.chat-text-input-window {
    width: 95vw !important;
}



.message-button {
    color: grey;
    margin: 0 10px;
}

.message-button:hover {
    color: rgb(2, 212, 2);
    cursor: pointer;
}

.message-time {
    font-size: .6rem;
    color: grey;
}

.status-ticker {
    height: 20px;
    margin: 0;
}
